<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-dialog max-width="700" v-model="dialog" hide-overlay persistent>
        <!--          <v-col cols="12" sm="8" md="8">-->
        <v-card class="elevation-9" max-width="750px">
          <v-window v-model="step">

            <v-window-item :value="1">
              <v-row>
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 tertiary--text text--accent-3">
                      {{ $t("Sign in to Diprella") }}
                    </h1>
                    <!--                        <div class="text-center mt-4">-->
                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-facebook-f</v-icon>-->
                    <!--                          </v-btn>-->

                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-google-plus-g</v-icon>-->
                    <!--                          </v-btn>-->
                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-linkedin-in</v-icon>-->
                    <!--                          </v-btn>-->
                    <!--                        </div>-->
                    <h4 class="text-center mt-4" style="min-height: 10px">{{ form_message }}</h4>
                    <v-form @submit.prevent="login">
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          :rules="[rule.required, rule.email, rule.min,]"
                          v-model="form.email"
                          @click="message_email=[]"
                          :error-messages="message_email"
                      />
                      <v-text-field
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          :rules="[ rule.min, rule.max]"
                          v-model="form.password"
                          :error-messages="message_password"
                          @click="message_password=[]"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="password_show ? 'text' : 'password'"
                          @click:append="password_show = !password_show; "
                          hint="Password must be at least 7 characters, contain an
                                                symbols and a number."
                      />
                      <h3 class="text-center mt-4" @click="step=3">{{ $t("message.forgotPassword") }}</h3>
                      <div class="text-center mt-3">
                        <v-btn rounded color="tertiary accent-1" dark type="submit">{{ $t("items.signIn") }}</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="4" class="tertiary accent-3">
                  <v-card-text class="white--text mt-4">
                    <h1 class="text-center display-1">{{ $t("message.hello") }}</h1>
                    <h5 class="text-center">{{ $t("message.registration") }}</h5>
                  </v-card-text>
                  <div class="text-center pa-5">
                    <v-btn rounded outlined dark @click="step=2">{{ $t("items.signUp") }}</v-btn>
                  </div>
                  <div class="text-center pa-5">
                    <v-row align="right">
                      <v-col cols="3">
                        <v-subheader>
                          eng
                        </v-subheader>
                      </v-col>
                      <v-col cols="6">
                        <v-switch
                            v-model="switch_lang"
                            inset
                        ></v-switch>
                      </v-col>
                      <v-col cols="3">
                        <v-subheader>
                          rus
                        </v-subheader>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col cols="12" md="4" class="tertiary accent-3">
                  <v-card-text class="white--text mt-3">
                    <h1 class="text-center display-1">{{ $t("Welcome Back!") }}</h1>
                    <h5
                        class="text-center"
                    >To Keep connected with us please login with your personnel info</h5>
                  </v-card-text>
                  <div class="text-center pa-5">
                    <v-btn rounded outlined dark @click="step--">{{ $t("Sign in") }}</v-btn>
                  </div>
                </v-col>

                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 tertiary--text text--accent-3">{{ $t("Create Account") }}</h1>
                    <!--                        <div class="text-center mt-4">-->
                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-facebook-f</v-icon>-->
                    <!--                          </v-btn>-->

                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-google-plus-g</v-icon>-->
                    <!--                          </v-btn>-->
                    <!--                          <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                            <v-icon>fab fa-linkedin-in</v-icon>-->
                    <!--                          </v-btn>-->
                    <!--                        </div>-->
                    <h4 class="text-center mt-4" style="min-height: 10px">{{ form_message }}</h4>
                    <v-form @submit.prevent="registration">
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          :rules="[rule.required, rule.email, rule.min,]"
                          v-model="form.email"
                          @click="message_email=[]"
                          :error-messages="message_email"
                      />
                      <v-text-field
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          :rules="[ rule.required, rule.min, rule.max]"
                          v-model="form.password"
                          :error-messages="message_password"
                          @click="message_password=[]"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="password_show ? 'text' : 'password'"
                          @click:append="password_show = !password_show"
                          hint="Password must be at least 7 characters, contain an
                                                symbols and a number."
                      />
                      <v-text-field
                          label="Password again"
                          prepend-icon="lock"
                          type="password"
                          :rules="[ rule.required, rule.pass_again]"

                      />
                      <div class="text-center mt-4">
                        <v-btn type="submit" rounded color="tertiary accent-3" dark>SIGN UP</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>

                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="3">
              <v-row class="fill-height">
                <v-col cols="12" md="4" class="tertiary accent-3">
                  <v-card-text class="white--text mt-3">
                    <h1 class="text-center display-1">Welcome Back!</h1>
                    <h5
                        class="text-center"
                    >To Keep connected with us please login with your personnel info</h5>
                  </v-card-text>
                  <div class="text-center pa-5">
                    <v-btn rounded outlined dark @click="step=1">Sign in</v-btn>
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 tertiary--text text--accent-3">New password</h1>
                    <h4 class="text-center mt-4" style="min-height: 10px">{{ form_message }}</h4>
                    <v-form @submit.prevent="reset_password">
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          :rules="[rule.required, rule.email, rule.min,]"
                          v-model="form.email"
                          @click="message_email=[]"
                          :error-messages="message_email"
                      />
                      <div class="text-center mt-4">
                        <v-btn type="submit" rounded color="tertiary accent-3" dark>SIGN UP</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="4">
              <v-row class="fill-height">
                <v-col cols="12" md="4" class="tertiary accent-3">
                  <v-card-text class="white--text mt-3">
                    <h1 class="text-center display-1">Welcome Back!</h1>
                    <h5
                        class="text-center"
                    >To Keep connected with us please login with your personnel info</h5>
                  </v-card-text>
                  <div class="text-center pa-5">
                    <v-btn rounded outlined dark @click="step=1">Sign in</v-btn>
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 tertiary--text text--accent-3">New password</h1>
                    <h4 class="text-center mt-4" style="min-height: 10px">{{ form_message }}</h4>
                    <v-form @submit.prevent="reset_password_confirm">
                      <v-text-field
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          :rules="[ rule.required, rule.min, rule.max]"
                          v-model="form.password"
                          :error-messages="message_password"
                          @click="message_password=[]"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="password_show ? 'text' : 'password'"
                          @click:append="password_show = !password_show"
                          hint="Password must be at least 7 characters, contain an
                                                symbols and a number."
                      />
                      <v-text-field
                          label="Password again"
                          prepend-icon="lock"
                          type="password"
                          :rules="[ rule.required, rule.pass_again]"

                      />
                      <div class="text-center mt-4">
                        <v-btn type="submit" rounded color="tertiary accent-3" dark>SIGN UP</v-btn>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="5">
              <v-row class="fill-height">
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 tertiary--text text--accent-3">
                      {{ $t("Sign in to Diprella") }}
                    </h1>
                    <div class="text-center mt-4">
                      <a :href="steam()">
                        <!--                                                    /accounts/social/signup/-->
                        <v-btn rounded color="primary" dark x-large>
                          STEAM
                        </v-btn>
                      </a>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="4" class="tertiary accent-3">
                  <v-card-text class="white--text mt-4">
                    <h1 class="text-center display-1">{{ $t("message.hello") }}</h1>
                    <h5 class="text-center">{{ $t("message.social_reg") }}</h5>
                  </v-card-text>
                  <div class="text-right pa-5">
                    <v-row align="center">
                      <v-col cols="3">
                        <v-subheader>
                          eng
                        </v-subheader>
                      </v-col>
                      <v-col cols="6">
                        <v-switch
                            v-model="switch_lang"
                            inset
                        ></v-switch>
                      </v-col>
                      <v-col cols="3">
                        <v-subheader>
                          rus
                        </v-subheader>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>

          </v-window>
        </v-card>
        <!--          </v-col>-->
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/views/accounts/api'
// import url from '@/views/login/url'

export default {
  data: vm => ({
    dialog: true,
    step: 1,
    switch_lang: true,
    password_show: false,
    token: '',
    uid: '',
    form_message: 'Ensure your email for login',
    form: {
      email: '',
      password: '',
    },
    message_password: [],
    message_email: [],
    rule: {
      required: value => !!value || "Required.",
      min: value => (value && value.length >= 7) || 'min 7 characters',
      max: value => (value || '').length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
      pass_again: value => (value === vm.form.password) || "Repeat password",
    },
  }),
  created: function () {
    // alert("token создан")
    // localStorage.setItem('access_token', 'f5f1140a128d23ead948636d07b1863d1250ab45')
    // this.$axios.get('/users/api/v1/token').then((response) => {
    //   this.save_token_and_go('/home', response.data.key)
    // })

    window.console.log('Значение $route: ', this.$route.fullPath)
    if (this.$route.fullPath.includes('login')) {
      this.step = 5
    }
    if ('token' in this.$route.params) {
      this.step = 4
      this.token = this.$route.params.token
      this.uid = this.$route.params.uid
      // this.form_message = 'rewrite your password'
      // window.console.log('Значение token: ', this.$route.params.token)
      // window.console.log('Значение uid: ', this.$route.params.uid)
    }
  },
  watch: {
    switch_lang: function (newValue) {
      if (newValue) {
        this.$i18n.locale = 'ru'
      } else {
        this.$i18n.locale = 'en'
      }
    },
    step: function (newStep, oldStep) {
      oldStep
      if (newStep === 1) {
        this.form_message = 'Введите данные для авторизации'
      }
      if (newStep === 2) {
        this.form_message = 'Введите данные для регистрации'
      }
      if (newStep === 3) {
        this.form_message = 'Введите почту отправки письма восстановления'
      }
      if (newStep === 4) {
        this.form_message = 'Введите новый пароль'
      }
    }
  },
  props: {
    source: String
  },
  methods: {
    login() {
      window.console.log('===login===')
      this.$axios.post(this.$url.rest_login, this.form)
          .then(response => {
            this.$store.state.token = response.data.key
            localStorage.setItem('access_token', this.$store.state.token)
            this.$router.push('/home')
            // this.save_token_and_go('/home', response.data.key)
          })
          .catch(error => {
            this.print_form(error.response)
          })
    },
    registration() {
      this.form_message = 'проверка данных'
      this.$axios.post(this.$url.registration, {
        email: this.form.email,
        password1: this.form.password,
        password2: this.form.password
      })
          .then(response => {
            response
            // window.console.log('===GOOD====', response)
            this.form_message = 'перейдите в почту для подтверждения ящика'
          })
          .catch(error => {
            // window.console.log('===error====', error.response.data)
            this.print_form(error.response)
          })
    },
    // async login_del() {
    //   this.form_message = 'проверка данных'
    //   window.console.log('10 login')
    //   const rez = await api.login(this.form.email, this.form.password)
    //   window.console.log('40 login', rez)
    //   if (this.no_error(rez)) {
    //     this.save_token_and_go('/home', rez.data.key)
    //   }
    // },
    // async registration_del() {
    //   this.form_message = 'проверка данных'
    //   const rez = await api.registration(this.form.email, this.form.password)
    //   if (this.no_error(rez)) {
    //     this.save_token_and_go('/home', rez.data.key)
    //   }
    // },
    save_token_and_go(page, token) {
      this.$store.state.token = token
      localStorage.setItem('access_token', this.$store.state.token)
      this.$router.push(page)
    },
    async reset_password() {
      this.form_message = 'проверка данных'
      const rez = await api.reset_password(this.form.email)
      if (this.no_error(rez)) this.form_message = 'check your email'
    },
    async reset_password_confirm() {
      this.form_message = 'проверка данных'
      const rez = await api.reset_password_confirm(this.form.password, this.token, this.uid)
      if (this.no_error(rez)) this.step = 1
    },
    no_error(srv) {
      this.form_message = ''
      window.console.log('response  --->', srv)
      if (srv.error) {
        this.form_message = 'error'
        if (srv.data.non_field_errors)
          this.form_message = srv.data.non_field_errors[0]
        if (srv.data.password)
          this.message_password = srv.data.password
        if (srv.data.new_password1)
          this.message_password = srv.data.new_password1
        if (srv.data.email)
          this.message_email = srv.data.email
        return false
      }
      return true
    },
    print_form(srv) {
      this.form_message = ''
      window.console.log('response  --->', srv)
      this.form_message = 'error'
      if (srv.data.non_field_errors)
        this.form_message = srv.data.non_field_errors[0]
      if (srv.data.password)
        this.message_password = srv.data.password
      if (srv.data.new_password1)
        this.message_password = srv.data.new_password1
      if (srv.data.email)
        this.message_email = srv.data.email
      return true
    },
    steam() {
      return this.$axios.defaults.baseURL + this.$url.steam_login
    },
  }
};
</script>

<i18n>
{
"en": {
"message": {
"forgotPassword": "Forgot your password ?",
"hello": "Hello, Friend!",
"registration": "Enter your personal details and start journay with us",
"social_reg": "Enter your personal details and start journay with us"
},
"items": {
"signUp": "SIGN UP",
"signIn": "SIGN IN"
}
},
"ru": {
"Sign in":"Войти",
"Create Account": "Регистрация",
"Welcome Back!": "С возвращением!",
"Sign in to Diprella": "Вход в систему",
"message": {
"forgotPassword": "Забыли пароль ?",
"hello": "Привет!",
"registration": "Перейдите на страницу регистрации если нет аккаунда",
"social_reg": "Вы будете перенаправленны на страницу STEAM для подтверждения аккаунда"
},
"items": {
"signUp": "Регистрация",
"signIn": "Войти"
}
}
}
</i18n>